/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * October 2021
 */

import React, {createContext, lazy, Suspense,  useEffect, useMemo, useState} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {
    IonApp, IonBadge,
    IonIcon,
    IonLabel,
    IonRouterOutlet,
    IonTabBar,
    IonTabButton,
    IonTabs
} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {home, person, receipt, cart} from 'ionicons/icons';
import TabHome from './pages/tabs/TabHome';
import TabProfile from "./pages/tabs/TabProfile";
import TabOrders from './pages/tabs/TabOrders';
import TabCart from "./pages/tabs/TabCart";
import Login from "./pages/auth/Login";
import AllCategories from "./pages/categories/AllCategories";
import Category from "./pages/categories/Category";
import ProductDetails from "./pages/products/ProductDetails";
import {EVENTS} from './lib/events/events';
import SearchProducts from "./pages/search/SearchProducts";
import Prescription from "./pages/checkout/Prescription";
import AddressList from "./pages/checkout/AddressList";
import ConfirmOrder from "./pages/checkout/ConfirmOrder";
import Payment from "./pages/checkout/Payment";
import OrderResult from "./pages/checkout/OrderResult";
import OrdersOperator from "./pages/operator/OrdersOperator";
import UploadTicketOperator from "./pages/operator/UploadTicketOperator";
import ProfileOperator from "./pages/operator/ProfileOperator";
import CardPicker from "./pages/paymentMethods/CardPicker";
import OrderPrescriptionsOperator from "./pages/operator/OrderPrescriptionsOperator";
import Info from "./pages/info/Info";
import Review from "./pages/reviews/Review";
import RequestResetPassword from "./pages/auth/RequestResetPassword";
import ValidatePasswordCode from "./pages/auth/ValidatePasswordCode";
import ChangePassword from "./pages/auth/ChangePassword";
import EditUser from "./pages/users/EditUser";
import Invoicing from "./pages/checkout/Invoicing";
import InvoicingList from "./pages/checkout/InvoicingList";
import UpdatePassword from "./pages/users/UpdatePassword";
import IdUpload from "./pages/checkout/IdUpload";

import Support from "./pages/info/Support";
import FinishedOrders from "./pages/operator/FinishedOrders";
import PaymentMethodsList from "./pages/paymentMethods/PaymentMethodsList";
import CouponsList from "./pages/coupons/CouponsList";
import {useHistory} from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
/* Data types */
import {User} from "./lib/data_types/dataTypes";
import SignIn from "./pages/auth/SignIn";
import NbioApi from "./lib/api/NbioApi";
import Cart from "./lib/cart/Cart";

import {Menu} from "./components/menu/Menu";
// URL Listener
import AppUrlListener from './components/app-url-listener/app-url-listener';
import Page404 from './pages/404/Page404';
import Discounts from "./pages/discounts/Discounts";
import AllBrands from "./pages/brands/AllBrands";
import Brand from "./pages/brands/Brand";
import ConfirmOrderGuest from "./pages/checkout/ConfirmOrderGuest";
import CartPopup from "./components/cart/CartPopup";
import UploadEvidenceOperator from "./pages/operator/UploadEvidenceOperator";
// import OrderDetailsOperator from './pages/operator/OrderDetailsOperator';
// import Address from './pages/checkout/Address';
// import OrderDetails from './pages/orders/OrderDetails';
// import OrderTracking from './pages/orders/OrderTracking';
const dummyUser: User = {
    name: '',
    last_name: '',
    source: '',
    uid: '',
    email: '',
    roles: ['user'],
    isDisabled: false,
    profileUrl: '',
    profileUrlThumbnail: ''
}

//auth interface
interface CartItemInterface {
    cartItem: number;
}

//context
export type UserContext = {
    user: User,
    setUser: (u: User) => void,
    isAuth: boolean,
    setIsAuth: (isAuth:boolean) => void
}
export const AppContext = createContext<UserContext>({
    user: dummyUser,
    setUser: () => {
    },
    isAuth:false,
    setIsAuth: () => {}
})
// Lazy
const Address = lazy(() => import('./pages/checkout/Address'));
const OrderDetailsOperator = lazy(() => import('./pages/operator/OrderDetailsOperator'));
const OrderDetails = lazy(() => import('./pages/orders/OrderDetails'));
const OrderTracking = lazy(() => import('./pages/orders/OrderTracking'));
const FAQ = lazy(() => import('./pages/info/FAQ'));
const PrivacyPolicy = lazy(() => import('./pages/info/PrivacyPolicy'));
const TOS = lazy(() => import('./pages/info/TOS'));
const PoiDetails = lazy(() => import('./pages/operator/PoiDetails'));


// const App
const App: React.FC = (props) => {
    const [user, setUser] = useState<User>(dummyUser);
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const value = useMemo(() => ({
        user, setUser, isAuth, setIsAuth
    }), [user,isAuth]);

    const [cartItem, setCartItem] = useState<CartItemInterface>({cartItem: 0});
    const [isUserChecked, setUserChecked] = useState<boolean>(false);
    const [firstRender, setFirstRender] = useState(true);

    const updateCartItem = () => {
        Cart.getCart().then((res) => {
            setCartItem({cartItem: res.data.countItems});
        }).catch((ex) => {
            setCartItem({cartItem: 0});
        })
    }
    const reportApp = () =>{
        NbioApi.t.rao().then((res) =>{
        }).catch((ex) =>{
        });
    }
    useEffect(() => {
        NbioApi.users.getMe().then((res) => {
            setUser(res.data.user);
            setIsAuth(true);
            setUserChecked(true);
            reportApp();
        }).catch((ex) => {
            setUserChecked(true);
            setIsAuth(false);
        })

        if(firstRender){
            updateCartItem();
            //creo que esto esta mal
            setFirstRender(false);
        }

        window.addEventListener(EVENTS.ADD_TO_CART, updateCartItem);
        window.addEventListener(EVENTS.UPDATE_CART, updateCartItem);

        return () => {
            window.removeEventListener(EVENTS.ADD_TO_CART, updateCartItem);
            window.removeEventListener(EVENTS.UPDATE_CART, updateCartItem);
        }
    }, [cartItem]);

    const renderBadge = () => {
        if (cartItem.cartItem > 0) {
            return (
                <IonBadge color={"danger"}>{cartItem.cartItem}</IonBadge>
            )
        }
    }

    const renderOperator = () => {
        return (
            <IonTabs>
                <IonRouterOutlet>
                    <Route exact path="/inicio">
                        <Redirect to={"/ordenes"}></Redirect>
                    </Route>
                    <Route path="/informacion"
                           render={props => <Info {...props}></Info>}>
                    </Route>
                    <Route exact
                           path="/ordenes"
                           render={props => <OrdersOperator {...props} />}>
                    </Route>
                    <Route exact
                           path="/ordenes-finalizadas"
                           render={props => <FinishedOrders {...props} />}>
                    </Route>
                    <Route exact path="/ordenes/:id"
                           render={props =>
                               <Suspense fallback={<></>}>
                                   <OrderDetailsOperator {...props}/>
                               </Suspense>
                           }>
                    </Route>
                    <Route exact path="/routes/:id/pois/:poiId"
                           render={props =>
                               <Suspense fallback={<></>}>
                                   <PoiDetails {...props}/>
                               </Suspense>
                           }>
                    </Route>
                    <Route exact path="/routes/:id/pois/:poiId/evidences"
                           render={props =>
                               <Suspense fallback={<></>}>
                                   <UploadEvidenceOperator {...props}/>
                               </Suspense>
                           }>
                    </Route>
                    <Route path="/ordenes/:id/recibos"
                           render={props => <UploadTicketOperator {...props} />}>
                    </Route>
                    <Route path="/ordenes/:id/recetas"
                           render={props => <OrderPrescriptionsOperator {...props} />}>
                    </Route>
                    <Route exact path="/perfil"
                           render={props => <TabProfile></TabProfile>}
                    >
                    </Route>
                    <Route path="/perfil/editar/"
                           render={props => <EditUser {...props}></EditUser>}
                    >
                    </Route>
                    <Route path="/perfil/actualizar-contrasena/"
                           render={props => <UpdatePassword {...props}></UpdatePassword>}
                    >
                    </Route>
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                    <IonTabButton tab="orders_operator" href="/ordenes">
                        <IonIcon icon={receipt}/>
                        <IonLabel>Órdenes</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="profile_operator" href="/perfil">
                        <IonIcon icon={person}/>
                        <IonLabel>Cuenta</IonLabel>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
        )
    }

    const pathName = window.location.pathname; // TODO: Change to hooks
    if(pathName === '/politicas-de-privacidad' && !isAuth){
        return (
            <IonReactRouter>
                <Route path="/politicas-de-privacidad" exact={true}
                       render={props =>
                       <>
                           <Suspense fallback={<div></div>}>
                               <PrivacyPolicy {...props} />
                           </Suspense>
                       </>
                       }>
                </Route>
            </IonReactRouter>
        )
    }
    if(pathName === '/terminos-y-condiciones' && !isAuth){
        return (
            <IonReactRouter>
                <Route path="/terminos-y-condiciones"
                       render={props =>
                           <>
                               <Suspense fallback={<div></div>}>
                                   <TOS {...props}/>
                               </Suspense>
                           </>
                       }>
                </Route>
            </IonReactRouter>
        )
    }
    if(pathName === '/soporte' && !isAuth){
        return (
            <IonReactRouter>
                <Route path="/soporte"
                       render={props => <Support {...props}/>}>
                </Route>
            </IonReactRouter>
        )
    }
    let showGuestConfirmOrder = false;
    if(!isAuth){
        showGuestConfirmOrder = process.env.REACT_APP_CAN_BUY_WITHOUT_ACCOUNT === 'true'
    }
    return (
        <IonApp>
            <AppContext.Provider value={value}>
                {
                    !isUserChecked ? null :
                        <IonReactRouter>
                            {/*This component listens for deep links and redirects to the URL*/}
                            <AppUrlListener></AppUrlListener>
                            <Menu></Menu>
                            {/*<Route exact path="/" >*/}
                            {/*    <Redirect to="/inicio"/>*/}
                            {/*</Route>*/}

                            {
                                isAuth && user?.roles.includes('operator') ? renderOperator() :
                                    <IonRouterOutlet  id={'app-menu-id'}>
                                        <Route exact
                                               path="/iniciar-sesion"
                                               render={props => <Login/>}>
                                        </Route>

                                        <Route exact
                                               path="/crear-cuenta"
                                               render={props => <SignIn/>}>
                                        </Route>
                                        <Route exact
                                               path="/solicitar-cambio-de-contrasena"
                                               render={props => <RequestResetPassword {...props}/>}>
                                        </Route>
                                        <Route exact
                                               path="/validar-codigo"
                                               render={props => <ValidatePasswordCode {...props}/>}>
                                        </Route>
                                        <Route exact
                                               path="/cambiar-contrasena"
                                               render={props => <ChangePassword {...props}/>}>
                                        </Route>
                                        <Route exact path="/direcciones"
                                               render={props => <AddressList {...props}/>}>
                                        </Route>
                                        <Route path="/direcciones/agregar"
                                               render={props =>
                                                   <Suspense fallback={<></>}>
                                                       <Address {...props}/>
                                                   </Suspense>
                                               }>
                                        </Route>
                                        <Route path="/direcciones/:id"
                                               render={props =>
                                                   <Suspense fallback={<></>}>
                                                       <Address {...props}/>
                                                   </Suspense>
                                               }>
                                        </Route>
                                        <Route exact path="/datos-de-facturacion"
                                               render={props => <InvoicingList {...props}/>}>
                                        </Route>
                                        <Route path="/datos-de-facturacion/agregar"
                                               render={props => <Invoicing {...props}/>}>
                                        </Route>
                                        <Route path="/datos-de-facturacion/:id"
                                               render={props => <Invoicing {...props}/>}>
                                        </Route>
                                        <Route exact
                                               path="/categorias"
                                               render={props => <AllCategories {...props}/>}>
                                        </Route>
                                        <Route path="/categorias/:id"
                                               render={props => <Category {...props}/>}>
                                        </Route>
                                        <Route exact
                                               path="/marcas"
                                               render={props => <AllBrands {...props}/>}>
                                        </Route>
                                        <Route path="/marcas/:id"
                                               render={props => <Brand {...props}/>}>
                                        </Route>
                                        <Route exact
                                               path="/descuentos"
                                               render={props => <Discounts {...props}/>}>
                                        </Route>

                                        <Route path="/confirmar-pedido"
                                               render={props =>
                                                   showGuestConfirmOrder ? <ConfirmOrderGuest/> : <ConfirmOrder {...props}></ConfirmOrder>
                                               }>
                                        </Route>
                                        <Route path={"/pagar"}
                                               render={props => <Payment {...props}/>}>
                                        </Route>
                                        <Route path={"/seleccionar-tarjeta"}
                                               render={props => <CardPicker {...props}/>}>
                                        </Route>
                                        <Route path={"/metodos-de-pago"}
                                               render={props => <PaymentMethodsList {...props}/>}>
                                        </Route>
                                        <Route path="/receta"
                                               render={props => <Prescription {...props}/>}>
                                        </Route>
                                        <Route path="/productos/:id"
                                               render={props => <ProductDetails {...props}
                                                                                onRecommendedClick={
                                                                                    (p: any) => {
                                                                                        window.scrollTo(0, 0);
                                                                                        // props.history.replace(`/productos/${p.sku}`)
                                                                                    }
                                                                                }/>}>
                                        </Route>
                                        <Route path="/busqueda"
                                               render={props =>
                                                   <SearchProducts {...props}></SearchProducts>}>
                                        </Route>
                                        <Route path="/" exact render={props =>
                                                   <Suspense fallback={<></>}>
                                                       <TabHome {...props}/>
                                                   </Suspense>
                                               }
                                        >
                                        </Route>
                                        <Route path="/inicio" exact
                                               render={props => <TabHome {...props}/>}
                                        >
                                        </Route>
                                        <Route exact path="/perfil"
                                               render={props => <TabProfile></TabProfile>}
                                        >
                                        </Route>
                                        <Route path="/perfil/editar/"
                                               render={props => <EditUser {...props}></EditUser>}
                                        >
                                        </Route>
                                        <Route path="/perfil/actualizar-contrasena/"
                                               render={props => <UpdatePassword {...props}></UpdatePassword>}
                                        >
                                        </Route>
                                        <Route exact path="/ordenes">
                                            <TabOrders/>
                                        </Route>
                                        <Route exact path="/ordenes/:id"
                                               render={props =>
                                                   <Suspense fallback={<></>}>
                                                       <OrderDetails {...props}/>
                                                   </Suspense>
                                               }>
                                        </Route>
                                        <Route path="/ordenes/:id/seguimiento"
                                               render={props =>
                                                   <Suspense fallback={<></>}>
                                                       <OrderTracking {...props}/>
                                                   </Suspense>
                                               }>
                                        </Route>
                                        <Route path={"/checkouts/:id"}
                                               render={props => <OrderResult {...props}/>}>
                                        </Route>
                                        <Route path="/carrito"
                                               render={props => <TabCart {...props}></TabCart>}>
                                        </Route>
                                        <Route path="/informacion"
                                               render={props => <Info {...props}></Info>}>
                                        </Route>
                                        <Route path="/preguntas-frecuentes"
                                               render={props =>
                                                   <Suspense fallback={<div></div>}>
                                                       <FAQ {...props}/>
                                                   </Suspense>
                                               }>

                                        </Route>
                                        <Route path="/resena/:id"
                                               render={props => <Review {...props}></Review>}>
                                        </Route>
                                        <Route path="/identificacion"
                                               render={props => <IdUpload {...props}/>}>
                                        </Route>
                                        <Route path="/politicas-de-privacidad"
                                               render={props =>
                                                   <Suspense fallback={<div></div>}>
                                                       <PrivacyPolicy {...props}/>
                                                   </Suspense>
                                               }>
                                        </Route>
                                        <Route path="/terminos-y-condiciones"
                                               render={props =>
                                                   <Suspense fallback={<div></div>}>
                                                       <TOS {...props}/>
                                                   </Suspense>
                                               }>
                                        </Route>
                                        <Route path="/soporte"
                                               render={props => <Support {...props}/>}>
                                        </Route>
                                        <Route exact
                                               path="/cupones"
                                               render={props => <CouponsList {...props}/>}>
                                        </Route>

                                        <Route
                                            render={props => <Page404></Page404>}>
                                        </Route>
                                    </IonRouterOutlet>
                            }


                        </IonReactRouter>

                }
            </AppContext.Provider>
        </IonApp>
    )
};

export default App;
