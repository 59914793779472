/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * January 2025
 */

import React, {useContext, useRef, useState} from "react";
import {
    IonItem,
    IonLabel,
    IonIcon,
    IonItemOption,
    IonItemOptions,
    IonItemSliding, IonSpinner, IonCard, IonCardContent, IonText, IonButton, IonItemDivider, IonBadge
} from "@ionic/react";
import type {OrderType, PoisType} from "../../lib/data_types/dataTypes";
import {
    checkmarkCircleSharp,
    cubeOutline,
    ellipse,
    hammerOutline,
    homeOutline,
    map,
    navigateSharp,
    stopwatch,
    waterOutline
} from "ionicons/icons";
import NbioApi from "../../lib/api/NbioApi";
import {AppContext} from "../../App";
import {money} from "../../lib/money/money";
import {AppLauncher} from "@capacitor/app-launcher";

//component
import OrderStatus from "../orders/OrderStatus";
import OrderPaymentMethod from "../orders/OrderPaymentMethod";
import OrderListItemOperator from "./OrderListItemOperator";


//interface
interface PoiListItemOperatorInterface {
    poi: PoisType;
    onOrderUpdated: any;
    index: number;
    poiCount:number;
    routeId:string;
}

const PoiListItemOperator: React.FC<PoiListItemOperatorInterface> = ({
                                                                         poi,
                                                                         index,
                                                                         routeId = '',
                                                                         onOrderUpdated = () => {
                                                                         },
    poiCount = 0
                                                                     }) => {
    const navigate = ()=>{

    }
    const iconMap = (action: string) => {
        if (action === 'PICK_UP') {
            return cubeOutline;
        } else if (action === 'DELIVER') {
            return homeOutline;
        } else if (action === 'FIX') {
            return hammerOutline;
        } else if (action === 'REPAIR') {
            return waterOutline;
        } else {
            return action;
        }
    }
    const iconLabel = (action: string) => {
        if (action === 'PICK_UP') {
            return "Recoger";
        } else if (action === 'DELIVER') {
            return "Entregar"
        } else if (action === 'REPAIR') {
            return "Reparar"
        } else if (action === 'GAS') {
            return "Gasolina"
        } else {
            return action;
        }
    }
    if (poi?.order) {
        return <IonCard>
            <IonItem lines={'none'}>
                <IonBadge>{index+1}/{poiCount}</IonBadge>
            </IonItem>

            <IonItem lines={'none'}>
                <IonIcon icon={iconMap(poi.action)}></IonIcon>
                <IonLabel>{iconLabel(poi.action)}</IonLabel>
                {/*<IonIcon icon={checkmarkCircleSharp}></IonIcon>*/}
            </IonItem>
            <OrderListItemOperator order={poi.order} index={index}
                                   onOrderUpdated={(order: OrderType) => onOrderUpdated(order._id)}>
            </OrderListItemOperator>
        </IonCard>

    }
    return (
        <IonCard>
            <IonItem className='ion-no-padding no-padding-inline-end'
                     lines={"full"}
                     detail routerLink={`/routes/${routeId}/pois/${poi._id}`}>
                <div className={'w-100'}>
                    <IonItem lines={'none'}>
                        <IonBadge>{index+1}/{poiCount}</IonBadge>
                    </IonItem>

                    <IonItem lines={'none'}>
                        <IonIcon icon={iconMap(poi.action)}></IonIcon>
                        <IonLabel>{iconLabel(poi.action)}</IonLabel>
                        {
                            poi.status === 'CLOSED' ?
                                <IonIcon icon={checkmarkCircleSharp} color={'primary'}></IonIcon> : null
                        }

                    </IonItem>
                    <IonItem lines={'none'}>
                        <IonLabel className='no-margin-top' slot={""}>Dirección:<br/>
                            <small>{poi.address}, {poi.borough}, {poi.zipCode}<br/>
                                Referencia: {poi.reference}
                            </small>
                        </IonLabel>

                    </IonItem>
                    <IonItem lines={'none'}>
                        <IonLabel>
                            Instrucciones:
                            <br/>
                            <small>
                                <IonText>{poi.instructions}</IonText>
                            </small>
                        </IonLabel>
                    </IonItem>
                    <IonItem className={'item-divider-route'} lines={'none'}>
                        <IonText slot="" className={'ion-text-wrapper ion-text-uppercase'}>
                        </IonText>
                        <div slot="end" className={'flex-col ion-margin-start'}>
                            <IonText className={''}>
                            </IonText>
                            <IonButton onClick={() => navigate()} className={'ion-margin-top'} size={'small'}
                                       color={'light'}>
                                <IonIcon icon={navigateSharp}>
                                </IonIcon>
                                &nbsp;Navegar
                            </IonButton>
                        </div>
                    </IonItem>
                </div>

            </IonItem>


        </IonCard>
    )

};
export default PoiListItemOperator;
