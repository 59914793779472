/* Copyright (C) Envialo México SA de CV - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by:
 * @author América Mendoza  <amendoza@nodeport.co>,
 * @author Darién Miranda <dmiranda@nodeport.co>,
 * @author Oscar Peña <opena@nodeport.co>,
 * December 2021
 */

import React from 'react';
import {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    withIonLifeCycle,
    IonButtons,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonItem,
    IonLabel,
    getPlatforms,
    IonSegment,
    IonSegmentButton,
    IonButton,
    IonPopover,
    IonItemGroup, IonItemDivider, IonText, IonNote
} from '@ionic/react';
import {RouteComponentProps} from "react-router";
import {ellipsisVertical, hourglass, receipt} from "ionicons/icons";
import {OrderType, PoisType, RouteType} from "../../lib/data_types/dataTypes";
import NbioApi from "../../lib/api/NbioApi";
import {Virtuoso} from "react-virtuoso";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {LocalNotifications, LocalNotificationSchema, ScheduleOptions} from "@capacitor/local-notifications";
import {Toast} from "@capacitor/toast";
import dayjs from "dayjs";
import {Capacitor} from "@capacitor/core";
import WSSClient from "../../lib/wss/wss-client";

//style
import "../../css/pages/operator/ordersOperator.css";

//components
import GradientDivider from "../../components/ui/GradientDivider";
import BtnToolbarLogo from "../../components/ui/BtnToolbarLogo";
import OrderListItemOperator from "../../components/operator/OrderListItemOperator";
import {AppLauncher} from "@capacitor/app-launcher";
import PoiListItemOperator from "../../components/operator/PoiListItemOperator";




interface OrdersOperatorProps extends RouteComponentProps {};

interface OrdersOperatorState {
    page: number;
    orders: OrderType[];
    isLoaded: boolean;
    isLoading: boolean;
    renderComponent?: string;
    selectedTab?: string;
    updatedDate:Date;
    endOrdersReached:boolean;
    viewIsLoaded:boolean,
    showPopover:boolean,
    popoverEvent: any,
    pageRoutes: number,
    routes: RouteType[],
    endRoutesReached: boolean
}

const showToast = async (msg: string) => {
    await Toast.show({
        text: msg
    })
}

class OrdersOperator extends React.Component<OrdersOperatorProps, OrdersOperatorState> {
    appIsLoadedInterval:any;
    constructor(props: OrdersOperatorProps) {
        super(props);
        this.state = {
            page: 1,
            orders: [],
            isLoaded: false,
            isLoading:false,
            renderComponent: 'ROUTES',
            selectedTab: '',
            endOrdersReached: false,
            updatedDate:new Date(),
            viewIsLoaded:false,
            showPopover:false,
            popoverEvent:undefined,
            pageRoutes: 1,
            routes: [],
            endRoutesReached: false
        }

        this.loadOrders                 = this.loadOrders.bind(this);
        this.loadRoutes                 = this.loadRoutes.bind(this);
        this.navigateGoogleMapsLink     = this.navigateGoogleMapsLink.bind(this);
        this.connectWSS();
    }

    wssOnMessage(event: any) {
        let jsonEvent = {
            nbioEvent: '',
            data: {
                orderId: '',
                uid:''
            }
        }
        try {
            jsonEvent = JSON.parse(event.data);
        } catch (e) {

        }

        switch (jsonEvent.nbioEvent) {

            case WSSClient.NBIO_EVENTS.ORDER_CREATED:
                return -1
                break;
            case WSSClient.NBIO_EVENTS.ORDER_UPDATED:
                this.updateOrder(jsonEvent.data.orderId);
                // if (jsonEvent.data.orderId === this.state.order._id) {
                //     this.getOrder();
                // }
                break;
            case WSSClient.NBIO_EVENTS.ORDER_ASSIGNED:
                this.onOrderAssigned(jsonEvent.data.uid)
                break;
            default:
                return -1;
        }
    }

    connectWSS() {
        const _this = this;
        // const orderId = this.props.match.params.id;
        WSSClient.startListening().then((socket) => {
            socket.addEventListener('message', (ev:any) => this.wssOnMessage(ev));
            socket.addEventListener('close', () => {
                socket.removeEventListener('message', () => this.wssOnMessage);
                setTimeout(() => {
                    _this.connectWSS();
                    // _this.getOrder();
                }, 3000)
            })
        }).catch((ex) => {
            setTimeout(() => {
                _this.connectWSS();
                // _this.getOrder();
            }, 3000)
        })
    }

    onOrderAssigned(uid:string){
        NbioApi.users.getMe().then((res) =>{
            const user = res.data.user;
            if(user._id === uid){
                this.setState({
                    page:1,
                    orders:[],
                    endOrdersReached:false
                }, () =>{
                    this.loadOrders();
                })
            }
        })
    }

    updateOrder(orderId:string){
        if(this.state.orders.map((o) => o._id).includes(orderId)) {
            NbioApi.orders.getOrder(orderId).then((res) => {
                // update order manually
                const orders = [...this.state.orders];
                const orderIndex = orders.findIndex((o) => o._id === orderId);
                orders[orderIndex] = res.data.order;
                this.setState({orders: orders, updatedDate: new Date()});
            }).catch((ex) => {
            })
        }
        // update route
        const routes = [...this.state.routes];
        for(let r = 0;r<routes.length;r++){
            const orders = routes[r].orders || [];
            const matchingOrder = orders.find((o) => o._id === orderId);
            const matchingOrderIndex = orders.findIndex((o) => o._id === orderId);
            if(matchingOrder){
                NbioApi.orders.getOrder(orderId).then((res) => {
                    // update order manually
                    routes[r].orders[matchingOrderIndex] = res.data.order;
                    this.setState({routes: routes, updatedDate: new Date()});
                }).catch((ex) => {
                })
                break;
            }
        }
    }

    ionViewDidEnter() {
        this.loadOrders();
        this.loadRoutes();
        const areNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        this.setState({viewIsLoaded:true})
        if (areNotificationsAvailable) {
            // PUSH NOTIFICATIONS
            PushNotifications.checkPermissions().then((res) => {
                if (res.receive !== 'granted') {
                    PushNotifications.requestPermissions().then((res) => {
                        if (res.receive === 'denied') {
                            showToast('Push Notification permission denied');
                        } else {
                            showToast('Push Notification permission granted');
                            this.register();
                        }
                    });
                } else {
                    this.register();
                }
            });
        }
    }

    loadRoutes = (showLoader=true) => {
        this.setState({isLoaded:false,isLoading:true})
        return new Promise((resolve,reject) => {
            if(this.state.endRoutesReached){
                return resolve(null);

            }
            return NbioApi.routes.getMyRoutes(this.state.pageRoutes).then(res => {
                const newRoutes = [...this.state.routes, ...res.data.routes];
                this.setState(
                    {
                        routes: newRoutes,
                        pageRoutes: this.state.pageRoutes + 1,
                        isLoaded: true,
                        isLoading: false,
                        endRoutesReached:res.data.routes.length === 0
                    }, () =>{
                        resolve(null);
                    });
            }).catch(ex => {
                this.setState({
                    isLoaded: true,
                    isLoading: false
                })
                reject(null);
            })
        });
    }

    loadOrders = (showLoader=true) => {
        this.setState({isLoaded:false,isLoading:true})
        return new Promise((resolve,reject) => {
            if(this.state.endOrdersReached){
                return resolve(null);
            }
            return NbioApi.orders.assigned(this.state.page, 10, 'pending').then(res => {
                const newOrders = [...this.state.orders, ...res.data.orders];
                this.setState(
                    {
                        orders: newOrders,
                        page: this.state.page + 1,
                        isLoaded: true,
                        isLoading: false,
                        endOrdersReached:res.data.orders.length === 0
                    }, () =>{
                        resolve(null);
                    });
            }).catch(ex => {
                this.setState({
                    isLoaded: true,
                    isLoading: false
                })
                reject(null);
            })
        });
    }

    renderEmptyMessage() {
        if (this.state.orders.length === 0 ) {
            return (
                <div className={'ion-padding ion-text-center'}>
                    {this.state.isLoading ? 'Cargando...' : 'No tienes órdenes'}
                    <br/>
                    <br/>
                    <IonIcon icon={this.state.isLoading ? hourglass : receipt} color={'medium'} size={'large'} slot={'icon-only'}></IonIcon>
                </div>
            )
        } else {
            return null;
        }

    }

    renderComponent() {
        switch (this.state.renderComponent) {
            case 'ROUTES': {
                return this.renderRoute();
            }
            case 'ORDERS': {
                return this.renderOrders();
            }
            case 'LOADING': {
                return this.renderLoader();
            }
            default: {
                return this.renderLoader();
            }
        }
    }

    renderRouteStatus = (status: string) => {
        let txt = '';
        switch (status) {
            case 'open': {
                txt = 'Abierta';
                break;
            }
            case 'on_route': {
                txt = 'En ruta';
                break;
            }
            case 'finished': {
                txt = 'Terminada';
                break;
            }
            default: {
                txt='---';
                break;
            }
        }
        return txt
    }

    navigateGoogleMapsLink = (route: any) => {
        const pois = route.pois || [];
        if(pois.length > 1){
            const origin = pois[0];
            const destination = pois[pois.length - 1];
            let waypoints = '';
            if(pois.length > 2){
                waypoints = pois.slice(1,pois.length - 1).map((poi: any) =>{
                    return [poi.latitude,poi.longitude].join(',')
                }).join('|');
            }
            try{
                const link = `
            https://www.google.com/maps/dir/?api=1&origin=${origin.latitude},${origin.longitude}
            &destination=${destination.latitude},${destination.longitude}&waypoints=${waypoints}`;
                // return link;
                return AppLauncher.openUrl({url:link});
            }catch(ex){
                console.log(ex);
                return '';
            }
        }
    }

    renderRoute = () => {
        return(
            <>
                <IonRefresher slot="fixed" onIonRefresh={(ev: any) => {
                    this.setState({
                        pageRoutes:1,
                        routes: [],
                        endRoutesReached:false
                    }, () => {
                        this.loadRoutes().then((res) => {
                            ev.detail.complete();
                        })
                    })
                }}>
                <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <Virtuoso
                    style={{height: '100%'}}
                    data={this.state.routes}
                    totalCount={this.state.routes.length}
                    endReached={() => this.loadRoutes()}
                    footer={ () => {
                        return (
                            <IonNote
                                color={'medium'}
                                className={'ion-padding flex-row ion-justify-content-center ion-align-items-center'}>
                                {this.state.endRoutesReached ? 'No tienes más rutas' : 'Cargando...' }
                            </IonNote>
                        )
                    }
                    }
                    itemContent={(index, route: RouteType) => {
                        const pois = route?.pois || [];
                        return (
                            <>
                                <IonItemGroup>
                                    <IonItemDivider className={'item-divider-route'} color={'primary'}>
                                            <IonText slot="" className={'ion-text-wrapper ion-text-uppercase'}>
                                                Ruta: {route.name}
                                            </IonText>
                                            <div slot="end" className={'flex-col ion-margin-start'}>
                                                <IonText className={''}>
                                                    {this.renderRouteStatus(route.status)}
                                                </IonText>
                                                <IonButton onClick={() => this.navigateGoogleMapsLink(route)} className={'ion-margin-top'}
                                                           size={'small'}  color={'light'}>
                                                    Ver ruta en Google Maps
                                                </IonButton>
                                            </div>
                                    </IonItemDivider>

                                    {
                                        pois.map((poi,i) =>{
                                            return (
                                                <PoiListItemOperator key={poi._id} poi={poi} index={i}
                                                                     poiCount={pois.length}
                                                                     routeId={route._id}
                                                                   onOrderUpdated={(order:OrderType) => this.updateOrder(order._id)}>
                                                 </PoiListItemOperator>
                                             )
                                        })
                                    }

                                </IonItemGroup>
                            </>

                        )
                    }}>
                </Virtuoso>
            </>

        )
    }

    renderOrders() {
        return (
            <>
                <IonRefresher slot="fixed" onIonRefresh={(ev: any) => {
                    this.setState({
                        page: 1,
                        orders: [],
                        updatedDate:new Date(),
                        endOrdersReached:false
                    }, () => {
                        this.loadOrders().then((res) => {
                            ev.detail.complete();
                        })
                    })
                }}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonItem lines={"none"}>
                    Fecha de actualización: {this.parseDate(this.state.updatedDate)}
                </IonItem>
                {
                    this.renderEmptyMessage()
                }
                <br/>
                <Virtuoso
                    style={{height: '100%'}}
                    data={this.state.orders}
                    totalCount={this.state.orders.length}
                    endReached={() => this.loadOrders()}
                    footer={ () => {
                        return (
                            <IonNote
                                color={'medium'}
                                className={'ion-padding flex-row ion-justify-content-center ion-align-items-center'}>
                                {this.state.endOrdersReached ? 'No tienes más órdenes' : 'Cargando...' }
                            </IonNote>
                        )
                    }
                    }
                    itemContent={(index, order: OrderType) => {
                        return (
                            <OrderListItemOperator key={order._id} order={order} index={index}
                                                   onOrderUpdated={(order:OrderType) => this.updateOrder(order._id)}>

                            </OrderListItemOperator>
                        )
                    }
                    }>
                </Virtuoso>
            </>
        )
    }

    renderLoader() {
        return null
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar className={"toolbar-nbio"}>
                        <IonButtons slot="start"><BtnToolbarLogo></BtnToolbarLogo></IonButtons>
                        <IonTitle>Órdenes</IonTitle>
                        <IonButtons slot="end">
                            <IonButton  size="large"
                                        fill={'clear'}
                                        onClick={(e: any) => {
                                            e.persist();
                                            this.setState({ showPopover: true, popoverEvent: e});
                                        }}>
                                <IonIcon slot="icon-only" icon={ellipsisVertical}></IonIcon>
                            </IonButton>
                        </IonButtons>

                        <IonPopover event={this.state.popoverEvent}
                                    isOpen={this.state.showPopover}
                                    onDidDismiss={() => this.setState({ showPopover: false, popoverEvent: undefined })}>
                            <IonItem lines={"none"}
                                     button
                                     onClick={(e: any) => {
                                         this.setState({ showPopover: false, popoverEvent: e });
                                         this.props.history.push("/ordenes-finalizadas");
                                     }}>
                                Órdenes finalizadas
                            </IonItem>
                        </IonPopover>
                    </IonToolbar>
                    <GradientDivider></GradientDivider>
                    <IonSegment onIonChange={(e) => {
                        this.setState({
                            renderComponent: e.detail.value,
                            selectedTab: e.detail.value,
                            page: e.detail.value === 'ORDERS' ? 1 : this.state.page,
                            pageRoutes: e.detail.value === 'ROUTES' ? 1 : this.state.pageRoutes,
                            orders: e.detail.value === 'ORDERS' ? [] : this.state.orders,
                            routes: e.detail.value === 'ROUTES' ? [] : this.state.routes,
                            endRoutesReached: e.detail.value === 'ROUTES' ? false : this.state.endRoutesReached,
                            endOrdersReached: e.detail.value === 'ORDERS' ? false : this.state.endOrdersReached
                        }, () =>{
                            if(e.detail.value === 'ROUTES'){
                                this.loadRoutes(true);
                            }else if(e.detail.value === 'ORDERS'){
                                this.loadOrders(true);

                            }
                        });
                        }} value={this.state.renderComponent}>
                        <IonSegmentButton value="ROUTES">
                            <IonLabel>Ruta</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="ORDERS">
                            <IonLabel>En proceso</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonHeader>
                <IonContent>
                    {this.renderComponent()}
                </IonContent>
            </IonPage>
        )
    }

    // TODO: REFACTOR & DELETE ME
    register() {
        // console.log('Initializing HomePage');

        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register()

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration',
            (token: Token) => {
                // console.log('token', token)
                NbioApi.users.updateFcmToken(token.value).then((res) => {
                    // console.log('FcmToken uploaded')
                }).catch((ex) => {
                    // console.log(ex)
                })

                // console.log('Push registration success');
            }
        );


        const platforms = getPlatforms();
        if (platforms.includes('android') || platforms.includes('ios')) {
            // console.log(platforms)
            // Some issue with our setup and push will not work
            PushNotifications.addListener('registrationError',
                (error: any) => {
                    // console.log('Error on registration: ' + JSON.stringify(error));
                }
            );
            // Show us the notification payload if the app-utils is open on our device
            PushNotifications.addListener('pushNotificationReceived',
                (notification: PushNotificationSchema) => {
                    // console.log('notification', notification)

                    let _notification = {
                        id: parseInt(notification.id),
                        title: notification.title,
                        body: notification.body,
                        extra: notification.data,
                        largeIcon:'ic_launcher',
                        smallIcon:'ic_launcher',
                    } as LocalNotificationSchema;
                    let options = {
                        notifications: [_notification]
                    } as ScheduleOptions;
                    LocalNotifications.schedule(options)
                })
            // Method called when tapping on a notification
            PushNotifications.addListener('pushNotificationActionPerformed',
                (notification: ActionPerformed) => {
                    // console.log('!notification', notification)
                    // console.log('data', notification.notification.data)
                    const data = notification.notification.data;
                    // console.log('data', data)
                    // CHECK EVERY SECOND IF VIEW IS LOADED
                    this.appIsLoadedInterval = setTimeout(() => {
                        // THEN PUSH
                        if (this.state.viewIsLoaded) {
                            clearInterval(this.appIsLoadedInterval);
                            if (data) {
                                if (data.action) {
                                    // console.log('action', data.action)
                                    if (data.action === 'ORDER_ASSIGNED') {
                                        this.props.history.push(`/ordenes/${data.orderId}`);
                                    }
                                }
                            }
                        }
                    },2000);
                    // TRY TO PUSH REGARDLESS
                    // if (data) {
                    //     if (data.action) {
                    //         // console.log('action', data.action)
                    //         if (data.action === 'ORDER_ASSIGNED') {
                    //             this.props.history.push(`/ordenes/${data.orderId}`);
                    //         }
                    //     }
                    // }
                    // setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
                }
            );
            LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
                // CHECK EVERY SECOND IF VIEW IS LOADED
                this.appIsLoadedInterval = setTimeout(() => {
                    // THEN PUSH
                    if (this.state.viewIsLoaded) {
                        clearInterval(this.appIsLoadedInterval);
                        const extra = notification.notification.extra;
                        if (extra.action === 'ORDER_ASSIGNED') {
                            this.props.history.push(`/ordenes/${extra.orderId}`);
                        }
                    }
                },2000);
                // if (notification.notification.extra) {
                //     const extra = notification.notification.extra;
                //     if (extra.action === 'ORDER_ASSIGNED') {
                //         setTimeout(() => {
                //             this.props.history.push(`/ordenes/${extra.orderId}`);
                //         },3000);
                //     }
                // }
            })
        }


    }

    parseDate(d: Date) {
        if(!d){
            return '-'
        }else{
            return dayjs(d).format('DD-MM-YYYY HH:mm:ss')
        }
    }
}

export default withIonLifeCycle(OrdersOperator);
